import React from "react";
import { useEffect } from 'react';
import { navigate } from 'gatsby';
import Layout from "../components/layout";
import CheckoutForm from "../components/checkout/checkout-form";

const Checkout = () => {
  useEffect(() => {
    var cart = localStorage.getItem("woo-next-cart");
    if(cart === null) navigate('/products/');
  }, []);
  return (
    <Layout>
      <div className="container my-5">
        <h1 className="mt-5 mb-4">Checkout Page</h1>
        <CheckoutForm />
      </div>
    </Layout>
  );
}

export default Checkout;
