import React from 'react';
import Error from "../error";

const Index = ( { input, handleOnChange } ) => {
	return (
		<div className="mt-3">
			<Error errors={ input.errors } fieldName={ 'paymentMethod' }/>
			{/*ChargeBee Gateway*/}
			<div className="form-check woo-next-payment-input-container mt-2">
				<label className="form-check-label">
					<input onChange={ handleOnChange } value="chargebee_gateway" className="form-check-input" name="paymentMethod" type="radio"/>
					<span className="woo-next-payment-content">ChargeBee Gateway</span>
				</label>
			</div>
			{/*Cash on Delivery*/}
			<div className="form-check woo-next-payment-input-container mt-2">
				<label className="form-check-label">
					<input onChange={ handleOnChange } value="cod" className="form-check-input" name="paymentMethod" type="radio"/>
					<span className="woo-next-payment-content">Cash on Delivery</span>
				</label>
			</div>
		</div>
	);
};

export default Index;
